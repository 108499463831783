.checkbox {
    margin-right: var(--space-xs);
    margin-bottom: var(--space-m);
}

.checked {
    background: url(./checked.png) center no-repeat;
    background-size: contain;
}

.text {
    text-align: start;
}

.unchecked {
    background: url(./unchecked.png) center no-repeat;
    background-size: contain;
}

.wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

@media (--desktop) {
    .checked,
    .unchecked {
        width: 4rem;
        height: 4rem;
    }

    .text {
        font-size: var(--font-l);
    }
}

@media (--tablet-landscape) {
    .checked,
    .unchecked {
        width: 3rem;
        height: 3rem;
    }

    .text {
        font-size: var(--font-m);
    }
}

@media (--tablet-portrait) {
    .checked,
    .unchecked {
        width: 4rem;
        height: 4rem;
    }

    .text {
        font-size: var(--font-l);
    }
}

@media (--mobile-landscape) {
    .checkbox {
        margin-bottom: var(--space-s);
    }

    .checked,
    .unchecked {
        width: 2rem;
        height: 2rem;
    }

    .text {
        font-size: var(--font-xs);
    }
}

@media (--mobile-portrait) {
    .checkbox {
        margin-bottom: var(--space-s);
    }

    .checked,
    .unchecked {
        width: 2rem;
        height: 2rem;
    }

    .text {
        font-size: var(--font-s);
    }
}
