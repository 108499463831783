.animation {
    margin: var(--space-m);
}

.back {
    display: flex;
    justify-content: flex-start;
}

.buttons {
    display: flex;
}

.finished {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.processing {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.wrapper {
    display: flex;
    justify-content: center;
    flex: 1;
}

@media (--desktop) {
    .animation {
        width: 36rem;
    }

    .back {
        position: absolute;
        margin-top: var(--space-xl);
    }

    .buttons {
        flex-direction: row;
    }

    .finished {
        justify-content: center;
    }

    .headline {
        font-size: var(--font-xxl);
        margin-bottom: var(--space-l);
    }

    .text {
        font-size: var(--font-xxl);
    }

    .video {
        margin-top: var(--space-xl);
    }
}

@media (--tablet-landscape) {
    .animation {
        width: 24rem;
    }

    .back {
        position: absolute;
        margin-top: var(--space-xl);
    }

    .buttons {
        flex-direction: row;
    }

    .finished {
        justify-content: center;
    }

    .headline {
        font-size: var(--font-xl);
        margin-bottom: var(--space-l);
    }

    .text {
        font-size: var(--font-xl);
    }
}

@media (--tablet-portrait) {
    .animation {
        width: 28rem;
    }

    .back {
        margin-top: var(--space-xl);
    }

    .buttons {
        flex-direction: column;
        margin-top: var(--space-l);
    }

    .headline {
        font-size: var(--font-xl);
        margin-bottom: var(--space-l);
    }

    .text {
        font-size: var(--font-xl);
    }

    .video {
        margin-top: var(--space-xl);
    }

    .wrapper {
        align-items: center;
    }
}

@media (--mobile-landscape) {
    .animation {
        width: 12rem;
    }

    .back {
        position: absolute;
        margin-top: var(--space-m);
    }

    .buttons {
        flex-direction: row;
        margin-top: var(--space-s);
    }

    .finished {
        justify-content: center;
    }

    .headline {
        font-size: var(--font-m);
        margin-bottom: var(--space-s);
    }

    .text {
        font-size: var(--font-m);
    }

    .video {
        margin-top: var(--space-m);
    }
}

@media (--mobile-portrait) {
    .animation {
        width: 90%;
    }

    .back {
        margin-top: var(--space-m);
    }

    .buttons {
        flex-direction: column;
        margin-top: var(--space-m);
    }

    .headline {
        font-size: var(--font-m);
        margin-bottom: var(--space-s);
    }

    .text {
        font-size: var(--font-m);
    }

    .video {
        margin-top: var(--space-m);
    }

    .wrapper {
        align-items: center;
    }
}
