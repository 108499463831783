.box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 75%;
    z-index: 999;
}

.input {
    margin-bottom: var(--space-m);
    border: 0.125rem solid var(--global-gray-color);
    font-family: 'lapsus_probold';
    color: var(--global-text-color);
    text-align: center;
}

.input:focus {
    outline: none;
}

.text,
.title {
    margin: var(--space-m);
}

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (--desktop) {
    .box {
        max-width: 40rem;
        padding: var(--space-xl);
    }

    .input {
        width: 12rem;
        height: 3rem;
        font-size: var(--font-l);
    }

    .text {
        font-size: var(--font-l);
    }

    .title {
        font-size: var(--font-xxl);
    }
}

@media (--tablet-landscape) {
    .box {
        padding: var(--space-l);
    }

    .input {
        width: 12rem;
        height: 3rem;
        font-size: var(--font-l);
    }

    .text {
        font-size: var(--font-l);
    }

    .title {
        font-size: var(--font-xl);
    }
}

@media (--tablet-portrait) {
    .box {
        padding: var(--space-l);
    }

    .input {
        width: 12rem;
        height: 3rem;
        font-size: var(--font-l);
    }

    .text {
        font-size: var(--font-m);
    }

    .title {
        font-size: var(--font-xl);
    }
}

@media (--mobile-landscape) {
    .box {
        padding: var(--space-m);
    }

    .input {
        width: 8rem;
        height: 2rem;
        font-size: var(--font-m);
    }

    .text {
        font-size: var(--font-s);
    }

    .title {
        font-size: var(--font-m);
    }
}

@media (--mobile-portrait) {
    .box {
        padding: var(--space-m);
    }

    .input {
        width: 8rem;
        height: 2rem;
        font-size: var(--font-m);
    }

    .text {
        font-size: var(--font-s);
    }

    .title {
        font-size: var(--font-m);
    }
}
