.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.text {
    padding: var(--space-m);
}

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (--desktop) {
    .spinner {
        width: 24rem;
    }

    .text {
        font-size: var(--font-l);
    }
}

@media (--tablet-landscape) {
    .spinner {
        width: 20rem;
    }

    .text {
        font-size: var(--font-l);
    }
}

@media (--tablet-portrait) {
    .spinner {
        width: 20rem;
    }

    .text {
        font-size: var(--font-l);
    }
}

@media (--mobile-landscape) {
    .spinner {
        width: 10rem;
    }

    .text {
        font-size: var(--font-s);
    }
}

@media (--mobile-portrait) {
    .spinner {
        width: 10rem;
    }

    .text {
        font-size: var(--font-s);
    }
}
