.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: var(--space-m);
}

.label {
    width: 100%;
    position: absolute;
    margin-top: 0.125rem;
}

.hidden {
    visibility: hidden;
}

.background {
    width: 100%;
    padding-top: calc(100% * 102 / 812);
    transition: background-image 0.3s ease-in-out;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('./backgrounds/mmf_progress_bar_000_percent.png');
}

.background_10 {
    background-image: url('./backgrounds/mmf_progress_bar_010_percent.png');
}

.background_25 {
    background-image: url('./backgrounds/mmf_progress_bar_025_percent.png');
}

.background_50 {
    background-image: url('./backgrounds/mmf_progress_bar_050_percent.png');
}

.background_66 {
    background-image: url('./backgrounds/mmf_progress_bar_066_percent.png');
}

.background_90 {
    background-image: url('./backgrounds/mmf_progress_bar_090_percent.png');
}

.background_99 {
    background-image: url('./backgrounds/mmf_progress_bar_099_percent.png');
}

.background_100 {
    background-image: url('./backgrounds/mmf_progress_bar_100_percent.png');
}

@media (--desktop) {
    .label {
        font-size: var(--font-xl);
        margin-top: 0.25rem;
    }

    .wrapper {
        width: 28rem;
    }
}

@media (--tablet-landscape) {
    .label {
        font-size: var(--font-l);
    }

    .wrapper {
        width: 20rem;
    }
}

@media (--tablet-portrait) {
    .label {
        font-size: var(--font-xl);
        margin-top: 0.25rem;
    }

    .wrapper {
        width: 70%;
    }
}

@media (--mobile-landscape) {
    .label {
        font-size: var(--font-s);
    }

    .wrapper {
        width: 12rem;
    }
}

@media (--mobile-portrait) {
    .label {
        font-size: var(--font-s);
    }

    .wrapper {
        width: 70%;
    }
}
