.box {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: var(--space-m);
}

.camera {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    background-color: white;
    cursor: pointer;
    transition: transform 0.1s ease-in-out;
    user-select: none;
}

.camera:active {
    transform: scale(0.95);
}

.placeholder {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
}

.replace {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: var(--space-m);
    margin-left: calc(var(--space-m) * -1);
    z-index: 100;
}

.spinner {
    position: absolute;
    margin: auto;
}

.upload {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 100;
}

img {
    max-width: 100%;
    height: auto;
}

@media (--desktop) {
    .box {
        width: 32rem;
    }

    .replace {
        width: 5rem;
        height: 4rem;
    }

    .spinner {
        width: 16rem;
    }

    .upload {
        width: 12rem;
        height: 10rem;
    }
}

@media (--tablet-landscape) {
    .box {
        width: 25rem;
    }

    .replace {
        width: 5rem;
        height: 4rem;
    }

    .spinner {
        width: 14rem;
    }

    .upload {
        width: 10rem;
        height: 8rem;
    }
}

@media (--tablet-portrait) {
    .box {
        width: 20rem;
    }

    .replace {
        width: 5rem;
        height: 4rem;
    }

    .spinner {
        width: 10rem;
    }

    .upload {
        width: 8rem;
        height: 6rem;
    }
}

@media (--mobile-landscape) {
    .box {
        width: 12rem;
    }

    .replace {
        width: 3rem;
        height: 2rem;
    }

    .spinner {
        width: 8rem;
    }

    .upload {
        width: 5rem;
        height: 4rem;
    }
}

@media (--mobile-portrait) {
    .box {
        width: 12rem;
        margin: var(--space-s);
    }

    .replace {
        width: 3rem;
        height: 2rem;
    }

    .spinner {
        width: 8rem;
    }

    .upload {
        width: 5rem;
        height: 4rem;
    }
}
