@media (--desktop) {
    .video {
        width: 64rem;
    }
}

@media (--tablet-landscape) {
    .video {
        width: 45rem;
    }
}

@media (--tablet-portrait) {
    .video {
        width: 80%;
    }
}

@media (--mobile-landscape) {
    .video {
        width: 65%;
    }
}

@media (--mobile-portrait) {
    .video {
        width: 80%;
    }
}
