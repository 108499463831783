.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.text {
    width: 50%;
    margin-right: var(--space-s);
}

.text-large {
    width: 65%;
    margin-right: var(--space-s);
}

.wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: transform 0.1s ease-in-out;
    user-select: none;
}

.wrapper:active {
    transform: scale(0.95);
}

@media (--desktop) {
    .text {
        margin-right: var(--space-m);
    }

    .wrapper {
        width: 18rem;
        height: 7rem;
        padding: var(--space-m);
        margin: var(--space-m);
        font-size: var(--font-xl);
    }
}

@media (--tablet-landscape) {
    .wrapper {
        width: 12rem;
        height: 4rem;
        padding: var(--space-m);
        margin: var(--space-s);
        font-size: var(--font-m);
    }
}

@media (--tablet-portrait) {
    .wrapper {
        width: 12rem;
        height: 4rem;
        padding: var(--space-m);
        margin: var(--space-s);
        font-size: var(--font-m);
    }
}

@media (--mobile-landscape) {
    .wrapper {
        width: 8rem;
        height: 3rem;
        padding: var(--space-s);
        margin: var(--space-s);
        font-size: var(--font-xs);
    }
}

@media (--mobile-portrait) {
    .wrapper {
        width: 8rem;
        height: 3rem;
        padding: var(--space-s);
        margin: var(--space-s);
        font-size: var(--font-xs);
    }
}
