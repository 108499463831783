.link {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: var(--space-s);
}

.wrapper {
    display: flex;
    flex-direction: row;
}

@media (--desktop) {
    .link {
        width: 18rem;
        height: 6rem;
        padding: var(--space-m);
    }
}

@media (--tablet-landscape) {
    .link {
        width: 12rem;
        height: 4rem;
        padding: var(--space-m);
    }
}

@media (--tablet-portrait) {
    .link {
        width: 12rem;
        height: 4rem;
        padding: var(--space-m);
    }

    .wrapper {
        flex-direction: column;
    }
}

@media (--mobile-landscape) {
    .link {
        width: 8rem;
        height: 2.75rem;
        padding: var(--space-s);
    }
}

@media (--mobile-portrait) {
    .link {
        width: 8rem;
        height: 2.75rem;
        padding: var(--space-s);
    }

    .wrapper {
        flex-direction: column;
    }
}
