.buttons {
    display: flex;
    flex-direction: row;
}

.images {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.next {
    align-self: flex-end;
    margin: 0;
}

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    padding: var(--space-m);
}

.center {
    align-self: center;
}

@media (--desktop) {
    .button {
        width: 20rem;
        height: 8rem;
    }

    .spinner {
        width: 24rem;
    }

    .text {
        font-size: var(--font-xxl);
    }

    .warning {
        max-width: 60rem;
        padding: var(--space-xl);
    }
}

@media (--tablet-landscape) {
    .button {
        width: 13rem;
        height: 5rem;
    }

    .spinner {
        width: 20rem;
    }

    .text {
        font-size: var(--font-xxl);
    }

    .warning {
        max-width: 40rem;
        padding: var(--space-l);
    }
}

@media (--tablet-portrait) {
    .button {
        width: 13rem;
        height: 5rem;
    }

    .buttons {
        flex-direction: column;
    }

    .spinner {
        width: 20rem;
    }

    .text {
        font-size: var(--font-xxl);
    }

    .warning {
        max-width: 60%;
        padding: var(--space-l);
    }
}

@media (--mobile-landscape) {
    .spinner {
        width: 10rem;
    }

    .text {
        font-size: var(--font-m);
    }

    .warning {
        max-width: 24rem;
        padding: var(--space-m);
    }
}

@media (--mobile-portrait) {
    .buttons {
        flex-direction: column;
    }

    .spinner {
        width: 10rem;
    }

    .text {
        font-size: var(--font-m);
    }

    .warning {
        max-width: 75%;
        padding: var(--space-m);
    }
}
