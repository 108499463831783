@media (--desktop) {
    .wrapper {
        margin: var(--space-m);
        font-size: var(--font-xl);
    }
}

@media (--tablet-landscape) {
    .wrapper {
        margin: var(--space-s);
        font-size: var(--font-xl);
    }
}

@media (--tablet-portrait) {
    .wrapper {
        margin: var(--space-s);
        font-size: var(--font-xl);
    }
}

@media (--mobile-landscape) {
    .wrapper {
        margin: var(--space-xs);
        font-size: var(--font-m);
    }
}

@media (--mobile-portrait) {
    .wrapper {
        margin: var(--space-xs);
        font-size: var(--font-m);
    }
}
