.animation {
    margin-bottom: var(--space-s);
}

.buttons {
    display: flex;
    flex-direction: row;
}

.processing {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding: var(--space-m);
}

@media (--desktop) {
    .animation {
        width: 36rem;
    }

    .text {
        font-size: var(--font-xxl);
    }
}

@media (--tablet-landscape) {
    .animation {
        width: 28rem;
    }

    .text {
        font-size: var(--font-xl);
    }
}

@media (--tablet-portrait) {
    .animation {
        width: 28rem;
    }

    .buttons {
        flex-direction: column;
    }

    .text {
        font-size: var(--font-xl);
    }
}

@media (--mobile-landscape) {
    .animation {
        width: 16rem;
    }

    .text {
        font-size: var(--font-m);
    }

    .wrapper {
        padding: 0;
    }
}

@media (--mobile-portrait) {
    .animation {
        width: 90%;
    }

    .buttons {
        flex-direction: column;
    }

    .text {
        font-size: var(--font-m);
    }
}
