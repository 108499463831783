.app {
    display: flex;
    justify-content: center;
}

.back {
    display: flex;
    justify-content: flex-start;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.link {
    display: flex;
    justify-content: center;
}

.text {
    margin: var(--space-m) 0;
}

.wrapper {
    display: flex;
    flex-direction: column;
}

@media (--desktop) {
    .back {
        margin-bottom: var(--space-xl);
    }

    .content {
        margin-top: var(--space-xxl);
    }

    .illustration {
        width: 45.625rem;
        order: 1;
    }

    .links {
        margin-top: var(--space-xxl);
        order: 4;
    }

    .steps {
        order: 2;
        margin-top: var(--space-l);
    }

    .text {
        font-size: var(--font-xl);
    }

    .video {
        width: 60%;
        max-width: 56rem;
        order: 3;
        margin-top: var(--space-xxl);
    }

    .wrapper {
        padding: var(--space-l);
    }
}

@media (--tablet-landscape) {
    .back {
        margin-bottom: var(--space-xl);
    }

    .illustration {
        width: 33.625rem;
        order: 1;
        margin-top: var(--space-xl);
    }

    .links {
        order: 4;
        margin-top: var(--space-xl);
    }

    .steps {
        order: 2;
        margin-top: var(--space-l);
    }

    .text {
        font-size: var(--font-l);
    }

    .video {
        width: 60%;
        max-width: 64rem;
        order: 3;
        margin-top: var(--space-xl);
    }

    .wrapper {
        padding: var(--space-l);
    }
}

@media (--tablet-portrait) {
    .back {
        margin-top: var(--space-xl);
        margin-bottom: var(--space-xl);
    }

    .illustration {
        width: 28rem;
        order: 2;
        margin-top: var(--space-xxl);
    }

    .links {
        width: 80%;
        order: 4;
        margin-top: var(--space-xl);
    }

    .steps {
        width: 60%;
        order: 3;
    }

    .text {
        font-size: var(--font-l);
    }

    .video {
        width: 80%;
        order: 1;
        margin-top: var(--space-xxl);
    }
}

@media (--mobile-landscape) {
    .back {
        margin-bottom: var(--space-m);
    }

    .content {
        margin-top: var(--space-l);
    }

    .illustration {
        width: 23.125rem;
        order: 1;
    }

    .links {
        order: 4;
        margin-top: var(--space-l);
    }

    .steps {
        order: 2;
    }

    .text {
        font-size: var(--font-s);
    }

    .video {
        width: 60%;
        order: 3;
        margin-top: var(--space-l);
    }

    .wrapper {
        padding: var(--space-m);
    }
}

@media (--mobile-portrait) {
    .back {
        margin-top: var(--space-m);
        margin-bottom: var(--space-m);
    }

    .illustration {
        width: 18rem;
        order: 2;
        margin-top: var(--space-xl);
    }

    .links {
        order: 4;
        margin-top: var(--space-xl);
    }

    .steps {
        width: 80%;
        order: 3;
    }

    .text {
        font-size: var(--font-s);
    }

    .video {
        width: 90%;
        order: 1;
        margin-top: var(--space-xl);
    }
}
