:root {
    --global-gray-color: #5f5f5f;
    --global-white-color: #fff;
    --global-text-color: var(--global-gray-color);
    --space-xs: 0.25rem;
    --space-s: 0.5rem;
    --space-m: 1rem;
    --space-l: 1.5rem;
    --space-xl: 2rem;
    --space-xxl: 3rem;
    --font-xs: 0.813rem;
    --font-s: 0.938rem;
    --font-m: 1.25rem;
    --font-l: 1.5rem;
    --font-xl: 2rem;
    --font-xxl: 3rem;
}

@font-face {
    font-family: 'lapsus_probold';
    src: local('lapsus_probold'),
        url(./fonts/lapsuspro-bold-webfont.ttf) format('woff');
}

body {
    font-family: 'lapsus_probold';
    color: var(--global-text-color);
    font-size: var(--font-m);
    text-align: center;
    min-width: 20rem;
}

a:link,
a:visited,
a:hover,
a:active {
    color: var(--global-text-color);
    text-decoration: none;
}

.App {
    text-align: center;
}

*:focus {
    outline: none;
}

html,
body {
    height: 100%;
    padding: 0;
    margin: 0;
}

html {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

#root {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}
