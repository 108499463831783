.group {
    display: flex;
}

.links {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding: var(--space-m);
}

@media (--desktop) {
    .group {
        flex-direction: row;
    }

    .text {
        margin: var(--space-xl);
        font-size: var(--font-xxl);
    }

    .wrapper {
        flex-direction: column;
    }
}

@media (--tablet-landscape) {
    .group {
        flex-direction: row;
    }

    .text {
        margin: var(--space-xl);
        font-size: var(--font-xl);
    }
}

@media (--tablet-portrait) {
    .group {
        flex-direction: row;
    }

    .text {
        margin: var(--space-xl);
        font-size: var(--font-xl);
    }
}

@media (--mobile-landscape) {
    .group {
        flex-direction: row;
    }

    .text {
        margin-bottom: var(--space-s);
        font-size: var(--font-m);
    }
}

@media (--mobile-portrait) {
    .group {
        flex-direction: column;
    }

    .text {
        margin-bottom: var(--space-m);
        font-size: var(--font-l);
    }
}
