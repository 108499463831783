.wrapper {
    font-family: 'lapsus_probold';
    color: var(--global-gray-color);
}

.stroke {
    -webkit-text-fill-color: var(--global-white-color);
    -webkit-text-stroke-width: 0.125rem;
    -webkit-text-stroke-color: var(--global-gray-color);
}
