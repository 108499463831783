.box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.title {
    margin-bottom: var(--space-m);
}

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (--desktop) {
    .box {
        max-width: 40rem;
        padding: var(--space-xl);
    }

    .title {
        font-size: var(--font-xxl);
    }
}

@media (--tablet-landscape) {
    .box {
        max-width: 50%;
        padding: var(--space-l);
    }

    .title {
        font-size: var(--font-xl);
    }
}

@media (--tablet-portrait) {
    .box {
        max-width: 60%;
        padding: var(--space-l);
    }

    .title {
        font-size: var(--font-xl);
    }
}

@media (--mobile-landscape) {
    .box {
        max-width: 50%;
        padding: var(--space-m);
    }

    .title {
        font-size: var(--font-m);
    }
}

@media (--mobile-portrait) {
    .box {
        max-width: 75%;
        padding: var(--space-m);
    }

    .title {
        font-size: var(--font-m);
    }
}
