.book,
.sample {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wrapper {
    display: flex;
}

@media (--desktop) {
    .cover,
    .image {
        width: 20rem;
    }

    .wrapper {
        flex-direction: row;
    }
}

@media (--tablet-landscape) {
    .cover,
    .image {
        width: 14rem;
    }

    .wrapper {
        flex-direction: row;
    }
}

@media (--tablet-portrait) {
    .book,
    .sample {
        margin: var(--space-m);
    }

    .cover,
    .image {
        width: 14rem;
    }

    .wrapper {
        flex-direction: column;
    }
}

@media (--mobile-landscape) {
    .cover,
    .image {
        width: 9rem;
    }

    .wrapper {
        flex-direction: row;
    }
}

@media (--mobile-portrait) {
    .book,
    .sample {
        margin: var(--space-s);
    }

    .cover,
    .image {
        width: 9rem;
    }

    .wrapper {
        flex-direction: column;
    }
}
