.camera {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 0 var(--space-m);
}

.camera:active {
    transform: scale(0.95);
}

.checkbox-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: var(--space-m);
}

.distance {
    margin: auto;
}

.icon {
    cursor: pointer;
}

.light {
    margin: auto;
}

.next {
    align-self: flex-end;
    margin: 0;
}

.spinner {
    margin: auto;
}

.spinner img {
    width: 100%;
    height: auto;
}

.tips {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding: var(--space-m);
}

img {
    max-width: 100%;
    height: auto;
}

@media (--desktop) {
    .camera {
        width: 12rem;
        height: 10rem;
    }

    .checkbox {
        height: 20%;
        margin-left: var(--space-l);
    }

    .checkbox-container {
        width: 25rem;
        height: 20rem;
    }

    .container {
        width: 25rem;
        height: 20rem;
        justify-content: space-between;
    }

    .headline {
        font-size: var(--font-xxl);
    }

    .spinner {
        width: 16rem;
    }

    .text {
        height: 20%;
        font-size: var(--font-l);
    }
}

@media (--tablet-landscape) {
    .camera {
        width: 9rem;
        height: 7rem;
    }

    .checkbox {
        height: 30%;
        margin-left: var(--space-l);
    }

    .checkbox-container {
        width: 18rem;
        height: 16rem;
    }

    .container {
        width: 18rem;
        height: 16rem;
        justify-content: space-between;
    }

    .headline {
        font-size: var(--font-xl);
    }

    .spinner {
        width: 11rem;
    }

    .text {
        height: 30%;
        font-size: var(--font-m);
    }
}

@media (--tablet-portrait) {
    .camera {
        width: 10rem;
        height: 9rem;
        padding: 0 var(--space-xl);
    }

    .checkbox-container {
        margin: var(--space-l);
    }

    .container {
        width: 21.625rem;
        justify-content: center;
        margin: var(--space-l);
    }

    .headline {
        font-size: var(--font-xl);
    }

    .spinner {
        width: 12rem;
    }

    .text {
        font-size: var(--font-l);
        margin-top: var(--space-m);
    }

    .tips {
        flex-direction: column;
        align-items: center;
    }

    .wrapper {
        margin-top: var(--space-xxl);
    }
}

@media (--mobile-landscape) {
    .camera {
        width: 6rem;
        height: 5rem;
        padding: 0 var(--space-s);
    }

    .checkbox {
        height: 30%;
        margin-left: var(--space-l);
    }

    .checkbox-container {
        width: 10rem;
        height: 10.5rem;
    }

    .container {
        width: 10rem;
        height: 10.5rem;
        justify-content: space-between;
        margin: 0 var(--space-s);
    }

    .headline {
        font-size: var(--font-m);
    }

    .spinner {
        width: 7rem;
    }

    .text {
        height: 30%;
        font-size: var(--font-s);
    }
}

@media (--mobile-portrait) {
    .camera {
        width: 6rem;
        height: 5rem;
    }

    .checkbox-container {
        width: 16rem;
        margin: var(--space-m) 0;
    }

    .container {
        width: 16rem;
        margin: var(--space-m) 0;
    }

    .headline {
        font-size: var(--font-m);
    }

    .spinner {
        width: 5rem;
    }

    .text {
        font-size: var(--font-s);
        margin-top: var(--space-m);
    }

    .tips {
        flex-direction: column;
        align-items: center;
    }

    .wrapper {
        padding: var(--space-m) 0;
    }
}
