.icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.wrapper:active {
    transform: scale(0.95);
}

@media (--desktop) {
    .wrapper {
        width: 8rem;
        height: 6rem;
    }
}

@media (--tablet-landscape) {
    .wrapper {
        width: 7rem;
        height: 5rem;
    }
}

@media (--tablet-portrait) {
    .wrapper {
        width: 7rem;
        height: 5rem;
    }
}

@media (--mobile-landscape) {
    .wrapper {
        width: 4rem;
        height: 3rem;
    }
}

@media (--mobile-portrait) {
    .wrapper {
        width: 4rem;
        height: 3rem;
    }
}
