.text {
    margin: var(--space-m);
}

.title {
    margin: var(--space-m);
}

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding: var(--space-m);
}

.privacy {
    text-align: left;
    max-width: 1000px;
}

@media (--desktop) {
    .subtitle {
        font-size: var(--font-l);
    }

    .text {
        font-size: var(--font-m);
    }

    .title {
        font-size: var(--font-xl);
    }
}

@media (--tablet-landscape) {
    .subtitle {
        font-size: var(--font-l);
    }

    .text {
        font-size: var(--font-m);
    }

    .title {
        font-size: var(--font-xl);
    }
}

@media (--tablet-portrait) {
    .subtitle {
        font-size: var(--font-l);
    }

    .text {
        font-size: var(--font-m);
    }

    .title {
        font-size: var(--font-xl);
    }
}

@media (--mobile-landscape) {
    .subtitle {
        font-size: var(--font-s);
    }

    .text {
        font-size: var(--font-xs);
    }

    .title {
        font-size: var(--font-m);
    }
}

@media (--mobile-portrait) {
    .subtitle {
        font-size: var(--font-s);
    }

    .text {
        font-size: var(--font-xs);
    }

    .title {
        font-size: var(--font-m);
    }
}
