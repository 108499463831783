.back {
    position: absolute;
}

.box {
    flex: 1;
    background-color: #fff;
    transform: rotate(-3deg);
    backface-visibility: hidden;
}

.image {
    align-self: center;
    position: absolute;
}

.logo {
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 1;
}

.wrapper {
    width: 100%;
}

@media (--desktop) {
    .back {
        margin-top: var(--space-xl);
    }

    .logo {
        width: 32rem;
        height: 15rem;
        margin: calc(var(--space-l) * -1) auto 0;
    }
}

@media (--tablet-landscape) {
    .back {
        margin-top: var(--space-xl);
    }

    .logo {
        width: 26rem;
        height: 12rem;
        margin: calc(var(--space-m) * -1) auto 0;
    }
}

@media (--tablet-portrait) {
    .back {
        margin-top: var(--space-xl);
    }

    .logo {
        width: 20rem;
        height: 10rem;
        margin: calc(var(--space-m) * -1) auto 0;
    }
}

@media (--mobile-landscape) {
    .back {
        margin-top: var(--space-m);
    }

    .logo {
        width: 12rem;
        height: 5rem;
        margin: calc(var(--space-s) * -1) auto 0;
    }
}

@media (--mobile-portrait) {
    .back {
        margin-top: var(--space-m);
    }

    .logo {
        width: 12rem;
        height: 5rem;
        margin: calc(var(--space-s) * -1) auto 0;
    }
}
