.container {
    width: calc(100% / 3);
    padding: var(--space-s);
}

.wrapper {
    display: flex;
}

@media (--desktop) {
    .headline {
        font-size: var(--font-xxl);
    }

    .text {
        font-size: var(--font-l);
    }

    .wrapper {
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
    }
}

@media (--tablet-landscape) {
    .headline {
        font-size: var(--font-xl);
    }

    .text {
        font-size: var(--font-m);
    }

    .wrapper {
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
    }
}

@media (--tablet-portrait) {
    .container {
        width: 100%;
    }

    .headline {
        font-size: var(--font-xl);
    }

    .text {
        font-size: var(--font-m);
    }

    .wrapper {
        flex-direction: column;
    }
}

@media (--mobile-landscape) {
    .headline {
        font-size: var(--font-m);
    }

    .text {
        font-size: var(--font-xs);
    }

    .wrapper {
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
    }
}

@media (--mobile-portrait) {
    .container {
        width: 100%;
    }

    .headline {
        font-size: var(--font-m);
    }

    .text {
        font-size: var(--font-xs);
    }

    .wrapper {
        flex-direction: column;
    }
}
